<template>
  <div class="c-field-message" :class="`c-field-message--${props.type}`">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { FieldMessageType } from './types/FieldMessageType';

const props = withDefaults(defineProps<{
  type?: FieldMessageType,
}>(), {
  type: 'error',
});

</script>

<script lang="ts">
export default {
  name: 'SeFieldMessage',
};
</script>
