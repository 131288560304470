<template>
  <div class="app-content container center-layout">
    <div class="flex align-items-center justify-content-center">
      <div class="auth-form-wrapper max-w-[448px] w-full xs:rounded-12 xs:shadow-medium py-16 xs:py-32 xs:px-24 mb-5 xs:mt-2 bg-neutral-0">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SeAuthFormWrapper',
};
</script>
